import React, { FC } from "react";
import { IconName, library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fasl } from "@fortawesome/sharp-light-svg-icons";
import { toClassName } from "helpers";
type TProps = {
  accessor: IconName;
  type?: "brands" | "light" | "regular" | "solid" | "sharp-light";
  className?: string;
};
const Icon: FC<TProps> = props => {
  const {
    accessor,
    type = "solid",
    className = "text-base"
  } = props;
  let component = <div></div>;
  switch (type) {
    case "brands":
      if (!(library as any)?.definitions.fab) {
        library.add(fab as any);
      }
      component = <FontAwesomeIcon icon={["fab", accessor]} className={className} />;
      break;
    case "solid":
      if (!(library as any)?.definitions.fas) {
        library.add(fas);
      }
      component = <FontAwesomeIcon icon={["fas", accessor]} className={className} />;
      break;
    case "light":
      if (!(library as any)?.definitions.fal) {
        library.add(fal);
      }
      component = <FontAwesomeIcon icon={["fal", accessor]} className={className} />;
      break;
    case "sharp-light":
      if (!(library as any)?.definitions.fasl) {
        library.add(fasl);
      }
      component = <FontAwesomeIcon icon={["fasl", accessor]} className={className} />;
      break;
  }
  return <div className={toClassName("w-[14px] sm:w-[16px]", className)} data-sentry-component="Icon" data-sentry-source-file="Icon.tsx">
      {component}
    </div>;
};
export default Icon;