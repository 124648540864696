import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const toClassName = (...values: (boolean | string | undefined)[]) => {
  return values.filter(Boolean).join(' ');
};

export const handleWait = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

export const handleGetData = async <T>(path: string) => {
  const endpoint = `http://localhost:3000/api${path}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(endpoint, options);
  const result = await response.json();

  if (!response.ok) {
    throw new Error(result);
  }

  return result as { data: T };
};

export const handleGenerateHTMLFromTranslation = (
  translation: string,
  map: { [key: string]: any },
) => {
  let result = translation;

  Object.keys(map).map((key) => {
    result = result.replace(`{{ ${key} }}`, map[key]);
  });

  return {
    __html: result,
  };
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleAddTwoHours = (date: any): Date => {
  // Check if date is a Date object
  // if (!(date instanceof Date)) {
  //   console.error('Input must be a Date object');
  //   return;
  // }
  // Create a new Date object to avoid mutating the original date
  var newDate = new Date(date.getTime());
  // Add two hours
  newDate.setHours(newDate.getHours() + 2);
  return newDate;
};

export const sortPerformances = (performances: any[]): any[] => {
  return performances.sort((a, b) => {
    const dateA = handleAddTwoHours(new Date(a?.dateOfPerformance));
    const dateB = handleAddTwoHours(new Date(b?.dateOfPerformance));

    const hourA = dateA.getHours();
    const hourB = dateB.getHours();

    // Convert hours to a custom sort order where PM hours come before AM hours
    const customHourA = hourA >= 12 ? hourA - 24 : hourA;
    const customHourB = hourB >= 12 ? hourB - 24 : hourB;

    if (customHourA !== customHourB) {
      return customHourA - customHourB;
    }

    // If the hour is the same, compare minutes
    return dateA.getMinutes() - dateB.getMinutes();
  });
};

export const handleSortByPerformanceTime = (a: any, b: any) => {
  const dateA = new Date(a?.attributes?.dateOfPerformance);
  const dateB = new Date(b?.attributes?.dateOfPerformance);

  const hourA = dateA.getHours();
  const hourB = dateB.getHours();

  // Convert hours to a custom sort order where PM hours come before AM hours
  const customHourA = hourA >= 12 ? hourA - 24 : hourA;
  const customHourB = hourB >= 12 ? hourB - 24 : hourB;

  if (customHourA !== customHourB) {
    return customHourA - customHourB;
  }

  // If the hour is the same, compare minutes
  return dateA.getMinutes() - dateB.getMinutes();
};

export const handleFixImageUrl = (url: string): string => {
  let result = url;

  if (typeof url === 'string' && url?.includes('/uploads/')) {
    const filename = url.split('/uploads/')[1];
    result = `https://storage.googleapis.com/anewagencyworld-com-bucket/strapi/${filename?.split('.')[0]}/${filename}`;
  }

  return result;
};
